export default (name, value, opt = {}) => {
    const options = {
        path: '/',
        ...opt,
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const optionKey in options) {
        updatedCookie += `; ${optionKey}`;
        const optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += `=${optionValue}`;
        }
    }

    document.cookie = updatedCookie;
};
