import React from 'react';
import SwiperCore, { Lazy, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import darkBg from '../common/images/placeholder/dark-bg_240x320.png';
import placeholder from '../common/images/placeholder/placeholder_237x395.png';
import { BASE_PATH } from '../../constants';

import './SliderTall.sass';

SwiperCore.use([Lazy, Pagination]);

const SliderTall = ({ htmlGames, styleType }) => {
    const mcn = cn('SliderTall');

    const sliderParams = {
        slidesPerView: 3,
        spaceBetween: 10,
        loop: true,
        preloadImages: false,
        lazy: true,
        pagination: true,
        breakpoints: {
            512: {
                spaceBetween: 20,
                slidesPerView: 4,
            },
        },
    };

    function renderItem(item) {
        const { id, name, tag } = item;
        let imgSrc;

        try {
            imgSrc = item.previews.filter(
                preview => preview.type === 'screens_240x320',
            )[0].link;
        } catch (e) {
            imgSrc = placeholder;
        }

        return (
            <SwiperSlide className={mcn('Item')} key={id}>
                <Link className={mcn('Link')} to={`/game-page/${id}`}>
                    <img className={mcn('Placeholder')} src={darkBg} alt="" />
                    <img className={classnames(mcn('Img'), 'swiper-lazy')} data-src={`${BASE_PATH}/storage/free/${imgSrc}`} alt="" />
                </Link>
                <span className={mcn('Text')}>
                    {name}
                    <br />
                    <span className={mcn('Tag')}>
                        #
                        {tag}
                    </span>
                </span>
            </SwiperSlide>
        );
    }

    function renderItems() {
        const bestGames = htmlGames.filter(item => item.bestGame);

        return (
            <Swiper {...sliderParams}>
                {bestGames.map(item => renderItem(item))}
            </Swiper>
        );
    }

    return (
        <div
            className={mcn({ style: styleType })}
        >
            <div className={mcn('Wrapper')}>
                {htmlGames.length ? renderItems() : null}
            </div>
        </div>
    );
};

SliderTall.propTypes = {
    htmlGames: PropTypes.arrayOf(
        PropTypes.shape({
            file: PropTypes.string,
            id: PropTypes.number,
            previews: PropTypes.array,
            type: PropTypes.string,
            tags: PropTypes.array,
        }),
    ).isRequired,
    styleType: PropTypes.string,
};

SliderTall.defaultProps = {
    styleType: 'light',
};

const mapStateToProps = state => ({
    htmlGames: state.games.htmlGames,
});

export default connect(mapStateToProps)(SliderTall);
