import React, { Component } from 'react';
import {
    Switch, Route, withRouter, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import { CSSTransition } from 'react-transition-group';
import PropTypes, { instanceOf } from 'prop-types';
import Header from '../Header/Header';
import PopupQR from '../PopupQR/PopupQR';
import HomePage from '../../pages/HomePage/HomePage';
import OnlinePage from '../../pages/OnlinePage/OnlinePage';
import AndroidPage from '../../pages/AndroidPage/AndroidPage';
import GamePageWrapper from '../../pages/GamePage/GamePageWrapper';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import ConditionsPage from '../../pages/ConditionsPage/ConditionsPage';
import CostPage from '../../pages/CostPage/CostPage';


class App extends Component {
    static propTypes = {
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }).isRequired,
        error: PropTypes.bool.isRequired,
        cookies: instanceOf(Cookies).isRequired,
        popupIsOpen: PropTypes.bool.isRequired,
    };

    state = {
        lastPath: '',
    };

    componentDidMount() {
        const { cookies } = this.props;
        const lastPath = cookies.get('lastPath');

        if (lastPath) this.setState({ lastPath });
    }

    componentDidUpdate(prevProps) {
        const { location: { pathname } } = this.props;

        if (prevProps.location.pathname !== pathname) {
            window.scrollTo(0, 0);
        }
    }

    redirectAndClear = () => {
        const { cookies } = this.props;
        const { lastPath } = this.state;
        cookies.remove('lastPath', { path: '/' });
        this.setState({ lastPath: '' });
        return <Redirect to={lastPath} />;
    };

    render() {
        const {
            location, error, popupIsOpen,
        } = this.props;
        const { lastPath } = this.state;
        const showLogo = location.pathname === '/'
            || location.pathname === '/cost'
            || location.pathname === '/conditions';

        if (error) return <ErrorPage pageType="wrong" />;

        return (
            <>
                <CSSTransition
                    in={popupIsOpen}
                    timeout={300}
                    unmountOnExit
                    classNames="PopupQR"
                >
                    <PopupQR />
                </CSSTransition>
                <Header isMainPage={showLogo} />
                <Switch>
                    <Route path="/offer" component={ConditionsPage} />
                    <Route path="/cost" component={CostPage} />
                    <Route path="/game-page/:id" component={GamePageWrapper} />
                    <Route path="/online" component={OnlinePage} />
                    <Route path="/android" component={AndroidPage} />
                    <Route path="/" exact component={HomePage} />
                    <Route component={ErrorPage} />
                </Switch>
                {lastPath && lastPath !== '' ? this.redirectAndClear() : null}
            </>
        );
    }
}

const mapStateToProps = state => ({
    error: state.games.error,
    errorMessage: state.games.errorMessage,
    popupIsOpen: state.games.popupIsOpen,
});

export default withRouter(connect(mapStateToProps)(withCookies(App)));
