import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';
import * as actionsContent from '../../actions/actionsContent';
import { BASE_PATH } from '../../constants';
import setCookie from '../../helpers/setCookie';

import './ErrorPage.sass';


const ErrorPage = ({ pageType, resetError }) => {
    const mcn = cn('ErrorPage');

    const renderText = () => {
        switch (pageType) {
        case '404':
            return 'Страница не найдена';

        case 'wrong':
            return 'что-то не так';

        default:
            return '';
        }
    };

    const clearAndResetError = () => {
        setCookie('tttt', '', {
            'max-age': -1,
        });
        resetError();
    };

    return (
        <div className={mcn()}>
            {pageType === 'wrong'
                ? <p className={mcn('LgText')}>Упс</p>
                : null}
            <p className={mcn('Text')}>
                {renderText()}
            </p>
            {pageType === '404'
                ? <p className={mcn('LgText')}> 404 </p>
                : null}
            <a
                className={classnames(mcn('Button'), cn('Button')({ style: 'dark' }))}
                type="button"
                href={BASE_PATH}
                onClick={clearAndResetError}
            >
                На главную
            </a>
        </div>
    );
};

ErrorPage.propTypes = {
    pageType: PropTypes.string,
    resetError: PropTypes.func.isRequired,
};

ErrorPage.defaultProps = {
    pageType: '404',
};

export default withRouter(connect(null, actionsContent)(ErrorPage));
