import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import './TagsFilter.sass';

SwiperCore.use([]);

class TagsFilter extends Component {
    static propTypes = {
        tags: PropTypes.arrayOf(PropTypes.string).isRequired,
        isAndroid: PropTypes.bool,
        onFilterSet: PropTypes.func.isRequired,
        current: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        bodyColor: PropTypes.string.isRequired,
        onlineGamesFilter: PropTypes.string.isRequired,
        androidGamesFilter: PropTypes.string.isRequired,
    };

    static defaultProps = {
        isAndroid: false,
    }

    getMcn = () => cn('TagsFilter');

    sliderParams = () => {
        const {
            isAndroid, tags, onlineGamesFilter, androidGamesFilter,
        } = this.props;
        const initialTagId = tags.indexOf(isAndroid ? androidGamesFilter : onlineGamesFilter) || 0;

        return ({
            initialSlide: initialTagId < 0 ? 0 : initialTagId,
            slidesPerView: 'auto',
            spaceBetween: 15,
            breakpoints: {
                520: {
                    spaceBetween: 30,
                },
            },
        });
    }

    renderItem = (item) => {
        const {
            current, color, bodyColor, onFilterSet,
        } = this.props;
        const activeFilter = current === '' ? 'all' : current;

        return (
            <SwiperSlide className={this.getMcn()('Item')} key={item}>
                <button
                    type="button"
                    onClick={() => onFilterSet(item !== 'all' ? item : '')}
                    className={classnames(this.getMcn()(
                        'Button',
                        {
                            active: activeFilter === item,
                            color,
                            body: bodyColor,
                        },
                    ))}
                >
                    #
                    {item}
                </button>
            </SwiperSlide>
        );
    };

    renderItems = () => {
        const { tags } = this.props;

        return (
            <Swiper {...this.sliderParams()}>
                {this.renderItem('all')}
                {tags.map(item => this.renderItem(item))}
            </Swiper>
        );
    };

    render() {
        const { tags } = this.props;

        return (
            <div className={this.getMcn()()}>
                {tags.length ? this.renderItems() : null}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    onlineGamesFilter: state.games.onlineGamesFilter,
    androidGamesFilter: state.games.androidGamesFilter,
});

export default connect(mapStateToProps)(TagsFilter);
