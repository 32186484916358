import React, { PureComponent } from 'react';
import { cn } from '@bem-react/classname';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import SwiperCore, { Controller, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BASE_PATH } from '../../constants';

import './SliderGame.sass';

SwiperCore.use([Controller, Pagination, Navigation]);

class SliderGame extends PureComponent {
    static propTypes = {
        vertical: PropTypes.bool.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }).isRequired,
        previews: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                type: PropTypes.string,
                file: PropTypes.string,
            }),
        ).isRequired,
    };

    constructor(props) {
        super(props);
        this.sliderRef = React.createRef();
        this.thumbnailRef = React.createRef();
    }

    state = {
        slider: null,
        thumbnail: null,
    };

    componentWillUpdate(nextProps, nextState) {
        const { location: nextLocation } = nextProps;
        const { location } = this.props;
        const prevPath = location.pathname;
        const nextPath = nextLocation.pathname;
        const { slider, thumbnail } = nextState;

        if (nextState.slider && nextState.thumbnail) {
            slider.current.swiper.controller.control = thumbnail.current.swiper;
            thumbnail.current.swiper.controller.control = slider.current.swiper;
        }

        if (prevPath !== nextPath) {
            this.sliderRef.current.swiper.slideTo(0);
        }
    }

    getMcn = () => cn('SliderGame');

    sliderParams = () => {
        const { vertical } = this.props;

        return {
            slidesPerView: vertical ? 2 : 1,
            spaceBetween: 10,
            navigation: true,
            onSwiper: () => {
                this.setState({
                    slider: this.sliderRef,
                });
            },
            controller: this.thumbnailRef,
        };
    };

    thymbnailParams = () => {
        const { vertical } = this.props;

        return {
            spaceBetween: 10,
            slidesPerView: vertical ? 3 : 2,
            touchRatio: 0.2,
            slideToClickedSlide: true,
            observer: true,
            pagination: true,
            onSwiper: () => {
                this.setState({
                    thumbnail: this.thumbnailRef,
                });
            },
            controller: this.sliderRef,
            breakpoints: {
                520: {
                    slidesPerView: vertical ? 4 : 3,
                },
            },
        };
    };

    renderItem = (item) => {
        const { id, link } = item;

        return (
            <SwiperSlide className={this.getMcn()('Item')} key={id}>
                <img src={`${BASE_PATH}/storage/free/${link}`} alt="" className={this.getMcn()('Img')} />
            </SwiperSlide>
        );
    };

    renderThumbnailItem = (item) => {
        const { id, link } = item;

        return (
            <SwiperSlide key={id}>
                <img src={`${BASE_PATH}/storage/free/${link}`} alt="" className={this.getMcn()('ThumbImg')} />
            </SwiperSlide>
        );
    };

    renderItems = () => {
        try {
            const { previews, vertical } = this.props;

            return (
                <>
                    <div className={this.getMcn()('SwiperMain')}>
                        <Swiper
                            {...this.sliderParams()}
                            ref={this.sliderRef}
                        >
                            {previews.map(item => this.renderItem(item))}
                        </Swiper>
                    </div>
                    <div className={this.getMcn()('SwiperThumb', { items: vertical ? 4 : 3 })}>
                        <Swiper
                            {...this.thymbnailParams()}
                            ref={this.thumbnailRef}
                        >
                            {previews.map(item => this.renderThumbnailItem(item))}
                        </Swiper>
                    </div>
                </>
            );
        } catch (e) {
            return null;
        }
    };

    render() {
        const { previews } = this.props;

        return (
            <div className={this.getMcn()()}>
                {previews.length ? this.renderItems() : null}
            </div>
        );
    }
}

export default withRouter(SliderGame);
