/* eslint-disable react/jsx-filename-extension */

import React from 'react';
import { render } from 'react-dom';
import 'normalize.css';
import 'swiper/swiper.scss';
import 'swiper/components/controller/controller.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/lazy/lazy.scss';
import './components/common/styles/main.sass';
import Root from './root';
import store from './store';
import { fetchData } from './actions/actionsContent';

store.dispatch(fetchData());


const mounthNode = document.getElementById('root');
render(<Root />, mounthNode);

const { hot } = module;

if (hot) {
    hot.accept();
}
