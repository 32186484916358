import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cn } from '@bem-react/classname';
import * as actionsContent from '../../actions/actionsContent';

import './Footer.sass';


class Footer extends Component {
    static propTypes = {
        backgroundColor: PropTypes.string,
        fetchFooter: PropTypes.func.isRequired,
        footer: PropTypes.shape({
            __html: PropTypes.string,
        }).isRequired,
    };

    static defaultProps = {
        backgroundColor: 'green',
    };

    componentDidMount() {
        const { fetchFooter } = this.props;

        fetchFooter();
    }

    getMcn = () => cn('Footer');

    render() {
        const { backgroundColor, footer } = this.props;

        return (
            <footer className={this.getMcn()({ color: backgroundColor })}>
                <ul className={this.getMcn()('Links')}>
                    <li className={this.getMcn()('LinksItem')}>
                        <Link to="/cost" className={this.getMcn()('Link')}>Стоимость услуги</Link>
                    </li>
                    <li className={this.getMcn()('LinksItem')}>
                        <Link to="/offer" className={this.getMcn()('Link')}>Пользовательское соглашение</Link>
                    </li>
                </ul>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={footer} className={this.getMcn()('Text')} />
            </footer>
        );
    }
}

const mapStateToProps = state => ({
    footer: state.games.footer,
});

export default connect(mapStateToProps, actionsContent)(Footer);
