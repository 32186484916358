import React, { Component } from 'react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cn } from '@bem-react/classname';
import Corner from '../Corner/Corner';
import placeholder from '../common/images/placeholder/placeholder-800x300-dark.png';
import { BASE_PATH } from '../../constants';

import './SliderMain.sass';

SwiperCore.use([Autoplay, Navigation]);

class SliderMain extends Component {
    static propTypes = {
        htmlGames: PropTypes.arrayOf(
            PropTypes.shape({
                file: PropTypes.string,
                id: PropTypes.number,
                previews: PropTypes.array,
                tag: PropTypes.string,
                type: PropTypes.string,
            }),
        ).isRequired,
    };

    getMcn = () => cn('SliderMain');

    sliderParams = () => ({
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        navigation: true,
        loop: true,
    });

    renderItem = (item) => {
        const { id } = item;
        let imgSrc;

        try {
            imgSrc = item.previews.filter(
                preview => preview.type === 'title_800x300',
            )[0].link;
        } catch (e) {
            imgSrc = placeholder;
        }

        return (
            <SwiperSlide className="slider-main__item" key={id}>
                <img src={`${BASE_PATH}/storage/free/${imgSrc}`} className={this.getMcn()('Image')} alt="" />
                <Link to={`/game-page/${id}`} className={this.getMcn()('Link')}>Играть</Link>
            </SwiperSlide>
        );
    };

    renderItems = () => {
        const { htmlGames } = this.props;
        const gamesOfTheWeek = htmlGames.filter(item => item.gameOfTheWeek);

        return (
            <Swiper {...this.sliderParams()}>
                {gamesOfTheWeek.map(item => this.renderItem(item))}
            </Swiper>
        );
    };


    render() {
        const { htmlGames } = this.props;

        return (
            <div className={this.getMcn()()}>
                {htmlGames.length ? this.renderItems() : null}
                <Corner lgText="Игры" text="недели" extraclass="SliderMain-Corner" />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    htmlGames: state.games.htmlGames,
});

export default connect(mapStateToProps)(SliderMain);
