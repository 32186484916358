import axios from 'axios';
import {
    ACTION_FETCH_DATA,
    ACTION_FETCH_CONDITIONS,
    ACTION_FETCH_FOOTER,
    ACTION_FETCH_COST,
    ACTION_SET_ONLINE_FILTER,
    ACTION_SET_ANDROID_FILTER,
    ACTION_RESET_ERROR,
    ACTION_POPUP_OPEN,
    ACTION_POPUP_CLOSE,
    BASE_PATH,
} from '../constants';
import restructureData from '../helpers/restructureData';

export const fetchData = () => async (dispatch) => {
    axios.get(`${BASE_PATH}/api/compact-auto`)
        .then((response) => {
            const { categories } = response.data;
            const [htmlGamesData] = categories.filter(item => item.alias === 'html-games');
            const [androidGamesData] = categories.filter(item => item.alias === 'android');
            const htmlGames = restructureData(htmlGamesData);
            const androidGames = restructureData(androidGamesData);
            dispatch({
                type: ACTION_FETCH_DATA,
                payload: {
                    htmlGames: htmlGames || [],
                    androidGames: androidGames || [],
                },
            });
        })
        .catch((error) => {
            if (error.response.status === 403) {
                axios.get(`${BASE_PATH}/api/status`)
                    .then((response) => {
                        const { data: { flow_url: flowUrl } } = response;
                        window.location.href = flowUrl;
                    });
            }
        });
};

export const fetchConditions = () => async (dispatch) => {
    try {
        const res = await axios.get(`${BASE_PATH}/text/online-games/offer/1`);

        dispatch({
            type: ACTION_FETCH_CONDITIONS,
            payload: res.data,
        });
    } catch (error) {
        dispatch({
            type: ACTION_FETCH_CONDITIONS,
            payload: 'Ошибка загрузки текста',
        });
    }
};

export const fetchFooter = () => async (dispatch) => {
    try {
        const res = await axios.get(`${BASE_PATH}/text/online-games/footer/1`);

        dispatch({
            type: ACTION_FETCH_FOOTER,
            payload: res.data,
        });
    } catch (error) {
        dispatch({
            type: ACTION_FETCH_FOOTER,
            payload: 'Ошибка загрузки текста',
        });
    }
};

export const fetchCost = () => async (dispatch) => {
    try {
        const res = await axios.get(`${BASE_PATH}/text/online-games/pricepage/1`);

        dispatch({
            type: ACTION_FETCH_COST,
            payload: res.data,
        });
    } catch (error) {
        dispatch({
            type: ACTION_FETCH_COST,
            payload: 'Ошибка загрузки текста',
        });
    }
};

export const setOnlineFilter = tag => ({
    type: ACTION_SET_ONLINE_FILTER,
    payload: tag,
});

export const setAndroidFilter = tag => ({
    type: ACTION_SET_ANDROID_FILTER,
    payload: tag,
});

export const resetError = () => ({
    type: ACTION_RESET_ERROR,
});

export const popupOpen = () => {
    document.body.classList.add('popup-open');

    return {
        type: ACTION_POPUP_OPEN,
    };
};

export const popupClose = () => {
    setTimeout(() => {
        document.body.classList.remove('popup-open');
    }, 300);

    return {
        type: ACTION_POPUP_CLOSE,
    };
};
