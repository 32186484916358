import React from 'react';
import { cn } from '@bem-react/classname';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actionsContent from '../../actions/actionsContent';
import code from './images/code.png';

import './PopupQR.sass';


const PopupQR = (props) => {
    const mcn = cn('PopupQR');
    const { popupClose } = props;

    return (
        <div className={mcn()}>
            <div className={mcn('Body')}>
                <button className={mcn('Close')} type="button" onClick={popupClose}>&nbsp;</button>
                <h3 className={mcn('Title')}>Зайди с мобильного</h3>
                <img src={code} alt="" />
                <p className={mcn('Text')}>
                    Сканируй QR-Код для перехода по ссылке или
                    просто заходи на
                    {' '}
                    <b>mgames.ru</b>
                    {' '}
                    с телефона!
                </p>
            </div>
        </div>
    );
};

PopupQR.propTypes = {
    popupClose: PropTypes.func.isRequired,
};

export default connect(null, actionsContent)(PopupQR);
