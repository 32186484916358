import React from 'react';
import { connect } from 'react-redux';
import { cn } from '@bem-react/classname';
import PropTypes from 'prop-types';
import { isIOS, isBrowser } from 'react-device-detect';
import SliderMain from '../../components/SliderMain/SliderMain';
import SliderTall from '../../components/SliderTall/SliderTall';
import SliderPre from '../../components/SliderPre/SliderPre';
import SliderWide from '../../components/SliderWide/SliderWide';
import GamesList from '../../components/GamesList/GamesList';
import Button from '../../components/Button/Button';
import ErrorBoudary from '../../components/ErrorBoundary/ErrorBoundary';
import Footer from '../../components/Footer/Footer';
import { BASE_PATH } from '../../constants';
import * as actionsContent from '../../actions/actionsContent';

import './HomePage.sass';


const HomePage = ({ androidGames, popupOpen }) => {
    const mcn = cn('HomePage');

    function getFileData() {
        return androidGames.filter(i => i.gameOfTheWeek)[0];
    }

    function renderContentLink(name) {
        return isBrowser
            ? (
                <button
                    className="Button Button_style_light"
                    type="button"
                    onClick={popupOpen}
                >
                    {name}
                </button>
            )
            : (
                <a
                    className="Button Button_style_light"
                    href={`${BASE_PATH}/storage/d/${getFileData().uidx}/${getFileData().fileHash}/`}
                    download={`${getFileData().name}.apk`}
                >
                    Скачать
                </a>
            );
    }

    function renderAndroidSection() {
        return (
            <div className={mcn('Section', { name: 'android' })}>
                <div className="Main-Wrapper">
                    <h2 className="Main-H2">Android games</h2>
                </div>
                <ErrorBoudary>
                    <SliderPre />
                </ErrorBoudary>
                <div className={mcn('HelpWrapper')}>
                    <div className="Button-Wrapper">
                        {androidGames.length ? renderContentLink('Скачать') : null}
                    </div>
                    <ErrorBoudary>
                        <SliderWide />
                    </ErrorBoudary>
                    <Button text="Все игры" link="/android" styleType="light" />
                </div>
            </div>
        );
    }

    return (
        <>
            <ErrorBoudary>
                <SliderMain />
            </ErrorBoudary>
            <div className={mcn('Section', { name: 'online' })}>
                <div className="Main-Wrapper">
                    <h2 className="Main-H2">Online games</h2>
                </div>
                <ErrorBoudary>
                    <SliderTall styleType="light" />
                </ErrorBoudary>
                <ErrorBoudary>
                    <GamesList />
                </ErrorBoudary>
                <Button text="Все игры" link="/online" styleType="dark" />
            </div>
            {isIOS ? null : renderAndroidSection()}
            <Footer />
        </>
    );
};

HomePage.propTypes = {
    androidGames: PropTypes.arrayOf(
        PropTypes.shape({
            file: PropTypes.string,
            id: PropTypes.number,
            previews: PropTypes.array,
            tag: PropTypes.string,
            type: PropTypes.string,
        }),
    ).isRequired,
    popupOpen: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    androidGames: state.games.androidGames,
});

export default connect(mapStateToProps, actionsContent)(HomePage);
